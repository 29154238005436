import { getActiveUser, getScreens } from 'src/utils/utils';

const DEFAULT_SAFE_URL = '/tools/gateway/discovery';

const isWorkspaceAllowed = (workspace: string) => {
  const possibleSpacesForUser = getScreens(getActiveUser());
  const allowedWorkspace = possibleSpacesForUser.find(
    (w) => w.value === workspace,
  );
  return !!allowedWorkspace;
};

export const getWorkspaceAllowedOrFirst = (workspace: string) => {
  if (!workspace) {
    workspace = 'gateway';
  }
  const possibleSpacesForUser = getScreens(getActiveUser());
  const allowedWorkspace = possibleSpacesForUser.find(
    (w) => w.value === workspace,
  );
  return allowedWorkspace?.value || possibleSpacesForUser?.[0]?.value;
};

export const getWorkspaceFromUrl = (url: string) => {
  const workspace = url.includes('tools/admin')
    ? 'admin'
    : url.includes('tools/vendor')
      ? 'vendor'
      : url.includes('tools/flash')
        ? 'flash'
        : url.includes('tools/tracker')
          ? 'tracker'
          : url.includes('tools/mlops')
            ? 'mlops'
            : url.includes('tools/predictor')
              ? 'predictor'
              : url.includes('tools/vehicle')
                ? 'vehicle'
                : url.includes('tools/askgalaxy')
                  ? 'askgalaxy'
                  : url.includes('tools/dem')
                    ? 'dem'
                    : 'gateway';

  const possibleSpacesForUser = getScreens(getActiveUser());
  const foundSpace = possibleSpacesForUser.find((w) => w.value === workspace);
  return foundSpace?.value;
};

const workspaceToUrl = {
  gateway: '/tools/gateway/discovery',
  admin: '/tools/admin/users',
  tracker: '/tools/tracker/dashboard',
  vendor: '/tools/vendor/dashboard/keys',
  flash: '/tools/flash/dashboard/ecu-flash',
  mlops: '/tools/mlops/tools',
  vehicle: '/tools/vehicle/dashboard/eol-dashboard',
  predictor: '/tools/predictor/dashboard/overview',
};

export const getUrlFromWorkspace = (workspace: string) => {
  return workspaceToUrl[workspace] || DEFAULT_SAFE_URL;
};

export const getUrlAllowedOrFirst = (returnUrl: string) => {
  const workspaceFromUrl = getWorkspaceFromUrl(returnUrl);
  if (isWorkspaceAllowed(workspaceFromUrl)) {
    return returnUrl;
  } else {
    return getHomeUrl();
  }
};

export const getHomeUrl = () => {
  const workspace = getWorkspaceAllowedOrFirst('');
  return getUrlFromWorkspace(workspace);
};

export const getRoleName = (roleName: string) => {
  const [rolePart, accessPart] = roleName.split('_');
  if (!accessPart) {
    return roleName;
  }
  return `${rolePart} ${accessPart === 'FullAccess' ? 'Admin' : 'Viewer'}`;
};
