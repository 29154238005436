import { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import * as Yup from 'yup';

import {
  Alert,
  Box,
  Button,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';
import { useAuth } from 'src/hooks/use-auth';
import { useMounted } from 'src/hooks/use-mounted';

import { useSettings } from 'src/hooks/use-settings';
import { restoreWorkspace } from 'src/slices/common-settings-slice';
import { useDispatch } from 'src/store';
import { Account } from 'src/tools/tracker/models/user';
import {
  getUrlAllowedOrFirst,
  getUrlFromWorkspace,
  getWorkspaceAllowedOrFirst,
  getWorkspaceFromUrl,
} from 'src/utils/is-allowed-workspace';

export const CompanySelection = (props: {
  onCompanySelection: (company: string) => void;
  companiesToSelect: Account[];
}) => {
  const { onCompanySelection, companiesToSelect } = props;
  const [availableCompanies, setAvailableCompanies] = useState<Account[]>([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [isProgress, setInProgress] = useState(false);

  useEffect(() => {
    setAvailableCompanies(companiesToSelect);
    setSelectedCompany(companiesToSelect?.[0]?.id || '');
  }, [companiesToSelect]);

  const handleCompanyChange = useCallback((event: any) => {
    setSelectedCompany(event.target.value);
  }, []);

  const handleCompanySelection = useCallback(() => {
    setInProgress(true);
    onCompanySelection?.(selectedCompany);
  }, [onCompanySelection, selectedCompany]);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          mt: 3,
        }}
      >
        <TextField
          select
          fullWidth
          variant="outlined"
          disabled={isProgress}
          label="Account"
          value={selectedCompany}
          onChange={handleCompanyChange}
        >
          {availableCompanies.map((opt: Account, idx) => (
            <MenuItem key={idx} value={opt.id}>
              {opt.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button
          fullWidth
          disabled={isProgress}
          size="large"
          type="submit"
          variant="contained"
          onClick={handleCompanySelection}
        >
          Select
        </Button>
      </Box>
    </>
  );
};

export const JWTLogin = (props: any) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const isMounted = useMounted();

  const { settings, saveSettings } = useSettings();
  const { login } = useAuth();

  const [showCompanySelection, setShowCompanySelection] = useState(false);
  const [companiesToSelect, setCompaniesToSelect] = useState([]);
  const [companySelectionCallback, setCompanySelectionCallback] =
    useState(null);

  const handleLogin = (
    urlToPush: string,
    workspaceToPush: string,
    account: Account,
  ) => {
    dispatch(restoreWorkspace(workspaceToPush));
    saveSettings({
      workspace: workspaceToPush,
      account: account,
    });
    router.push(urlToPush);
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      submit: null,
    },
    validationSchema: Yup.object({
      username: Yup.string().max(255).required('Username is required'),
      password: Yup.string().max(255).required('Password is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const [accounts, authorizeUserCalllback] = (await login(
          values.username,
          values.password,
        )) as any;

        if (!isMounted()) {
          return;
        }

        const returnUrl = router.query.returnUrl;
        const defaultWorkspace = getWorkspaceAllowedOrFirst('');
        const defaultUrl = getUrlFromWorkspace(defaultWorkspace);

        let urlToPush = '';
        let workspaceToPush = '';

        if (returnUrl) {
          workspaceToPush = getWorkspaceAllowedOrFirst(
            getWorkspaceFromUrl(returnUrl as string) || defaultWorkspace,
          );
          urlToPush = workspaceToPush
            ? getUrlAllowedOrFirst(returnUrl as string)
            : defaultUrl;
        } else {
          workspaceToPush = getWorkspaceAllowedOrFirst(settings.workspace);
          urlToPush = getUrlFromWorkspace(workspaceToPush) || defaultUrl;
        }

        // TODO: after all account
        // are provisioned
        //
        // if (!accounts?.length) {
        //   // TODO: alert
        // }

        if (accounts?.length > 1) {
          setShowCompanySelection(true);
          setCompaniesToSelect(accounts);
          // For useState it requires to have a function
          // that returns a function
          setCompanySelectionCallback(() => (accountId: string) => {
            authorizeUserCalllback?.();
            handleLogin(
              urlToPush,
              workspaceToPush,
              accounts?.filter((a: Account) => a?.id === accountId)?.[0],
            );
          });
        } else {
          authorizeUserCalllback?.();
          handleLogin(urlToPush, workspaceToPush, accounts?.[0]);
        }
      } catch (err) {
        console.error(err);

        if (!isMounted()) {
          return;
        }

        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  if (showCompanySelection) {
    return (
      <CompanySelection
        onCompanySelection={companySelectionCallback}
        companiesToSelect={companiesToSelect}
      />
    );
  }

  return (
    <form noValidate onSubmit={formik.handleSubmit} {...props}>
      <TextField
        autoFocus
        error={Boolean(formik.touched.username && formik.errors.username)}
        fullWidth
        helperText={formik.touched.username && formik.errors.username}
        label="Email"
        margin="normal"
        name="username"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="text"
        value={formik.values.username}
      />
      <TextField
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        label="Password"
        margin="normal"
        name="password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
      />
      {formik.errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{formik.errors.submit as any}</FormHelperText>
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Button
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {showCompanySelection ? 'Select' : 'Log In'}
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Alert severity="info">
          <div>Use E-mail account to login</div>
        </Alert>
      </Box>
    </form>
  );
};
